import React from 'react'

// images
import Gorgenauts1 from '../images/gorgenautsSfondo2.png'

export function Home() {
    return (
        <div className='page'>
            <img src={Gorgenauts1} />
            <h1>Ganzo, anzi, ganzissimo...</h1>
        </div>
    )
}